<template>
  <div class="top-page">
    <h1>menoah, Inc</h1>
    
    <section class="company-info">
      <h2>会社概要</h2>
      <dl>
        <dt>会社名</dt>
        <dd>メノア株式会社</dd>
        
        <dt>代表取締役</dt>
        <dd>
          <ul>
            <li>吉川雄司</li>
            <li>柿本竜也</li>
          </ul>
        </dd>
        
        <dt>設立日</dt>
        <dd>2024年6月3日</dd>
        
        <dt>所在地</dt>
        <dd>〒150-0002 東京都渋谷区 渋谷1-1-3 アミーホール</dd>
      </dl>
    </section>

    <section class="services">
      <h2>サービス</h2>
      <div class="service-item">
        <h3>更年期症状の傾向把握アプリ メノア</h3>
        <p>産婦人科医監修の更年期特化アプリです。更年期特有の症状や、症状が起こるきっかけ、生理日や経血量などを簡単に記録でき、それらを振り返って傾向を把握できます。</p>
        <p><a href="https://apps.apple.com/jp/app/id6736600712" target="_blank" rel="noopener noreferrer">App Storeでダウンロード</a></p>
      </div>
    </section>

    <section class="contact">
      <h2>お問合せ</h2>
      <p>Email: <a href="mailto:info@menoah.jp">info@menoah.jp</a></p>
    </section>
  </div>
</template>

<script>
export default {
  name: 'TOPView',
  mounted() {
    document.title = 'メノア株式会社';
  }
};
</script>

<style scoped>
.top-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  text-align: center;
  color: #333;
}

h2 {
  color: #444;
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
  margin-top: 30px;
}

dl {
  margin-left: 20px;
}

dt {
  font-weight: bold;
  margin-top: 10px;
}

dd {
  margin-left: 0;
  margin-bottom: 10px;
}

ul {
  padding-left: 20px;
}

.contact a {
  color: #0066cc;
  text-decoration: none;
}

.contact a:hover {
  text-decoration: underline;
}

.service-item {
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.service-item h3 {
  margin-top: 0;
  color: #333;
}

.service-item a {
  display: inline-block;
  margin-top: 10px;
  padding: 8px 15px;
  background-color: #0066cc;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-weight: bold;
}

.service-item a:hover {
  background-color: #0055aa;
}
</style>