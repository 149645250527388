<template>
        <header>
                <div class="header-container">
                        <div class="logo">
                                <img src="@/assets/images/Logo.svg" alt="menoah" />
                        </div>
                        <div class="download-button">
                                <button>ダウンロード</button>
                        </div>
                </div>
        </header>
</template>

<script>
export default {
        name: 'HeaderComponent'
};
</script>

<style>
header {
        text-align: center;
        padding: 20px;
        background-color: var(--color-white);
        color: var(--color-green-500);
}

.header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1200px;
        margin: 0 auto;
        width: 100%;
        padding: 0 40px;
}

.logo img {
        height: 29px;
}

.download-button button {
        background-color: var(--color-yellow-500);
        color: var(--color-green-600);
        border: none;
        border-radius: 25px;
        width: 143px;
        height: 43px;
        font-weight: bold;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
}

/* モバイル対応 */
@media (max-width: 768px) {
        .header-container {
                width: 100%;
                max-width: 390px;
                height: 72px;
                padding: 0 24px;
        }
        
        header {
                padding: 0;
        }
        
        .logo img {
                height: 24px;
        }
        
        .download-button button {
                width: 120px;
                height: 40px;
                font-size: 14px;
        }
}
</style>

